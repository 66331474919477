import { all, takeEvery, put, fork, call, takeLatest } from "redux-saga/effects";

import {contentAchievementService, taskService} from "@iso/services";
import actions from "@iso/redux/task/actions";
import { filter } from "lodash";

function* getTaskDataRequest() {
  yield takeEvery(actions.GET_TASKS_REQUEST, function* ({ payload }) {
    const {
      searchKeyword,
      storeId,
      status,
      priority,
      directorId,
      registerPersonId,
      assigneeId,
      size,
      page,
      offsetNumber,
      getTaskGrantChart,
      endDateStart,
      endDateEnd,
      registerDateStart,
      registerDateEnd,
      startDateStart,
      startDateEnd,
      searchTeam,
      personInCharge,
      taskFormat,
      sortBy,
      sortByType,
      resolve,
      reject,
    } = payload;
    try {
      const tasks = yield call(taskService.getTasks, {
        searchKeyword,
        storeId,
        status,
        priority,
        directorId,
        assigneeId,
        registerPersonId,
        endDateStart,
        endDateEnd,
        registerDateStart,
        registerDateEnd,
        startDateStart,
        startDateEnd,
        searchTeam,
        personInCharge,
        taskFormat,
        size,
        page,
        offsetNumber,
        sortBy,
        sortByType,
        resolve,
        reject,
      });
      const listTask = tasks
        ? tasks.tasks.map((child) => {
            if ((child.storeId !== null && child.parentTaskId == null) || child.parentTaskId !=null) {
              return child;
            }
            let obj = Object.assign({}, child);
            obj.children = [];
            return obj;
          })
        : [];
      const taskGrantChart = tasks
        ? filter(tasks.tasks, (task) => !task.dueDate)
        : [];
      yield put({
        type: actions.GET_TASKS_SUCCESS,
        tasks: tasks && !getTaskGrantChart ? listTask : taskGrantChart,
        total: tasks ? tasks.total : 0,
      });
      yield resolve(listTask);
    } catch (e) {
      yield put({
        type: actions.GET_TASKS_ERROR,
        error: e.message,
      });
    }
  });
}

function* addDataTasks() {
  yield takeEvery(actions.ADD_DATA_TASKS_REQUEST, function* ({ payload }) {
    const { oldTasks, newTasks } = payload;
    try {
      let tasks = oldTasks.concat(newTasks);
      yield put({
        type: actions.ADD_DATA_TASKS_SUCCESS,
        tasks: tasks,
      });
    } catch (e) {
      yield put({
        type: actions.ADD_DATA_TASKS_ERROR,
        error: e.response,
      });
    }
  });
}

function* getTasksMetaData() {
  yield takeEvery(actions.GET_TASKS_METADATA_REQUEST, function* ({ payload }) {
    const { chainId, list_role } = payload;
    try {
      const [directors, assignees, registerPersons, stores] = yield all([
        call(taskService.getDirectors, { currentDirector: null }),
        call(taskService.getAssignees, { list_role }),
        call(taskService.getRegisterPersons),
        call(taskService.getStores, { chainId }),
      ]);

      yield put({
        type: actions.GET_TASKS_METADATA_SUCCESS,
        directors: directors ? directors.accounts : [],
        assignees: assignees ? assignees.accounts : [],
        stores: stores ? stores.stores : [],
        registerPersons: registerPersons ? registerPersons.accounts : [],
      });
    } catch (e) {
      yield put({
        type: actions.GET_TASKS_METADATA_ERROR,
        error: e.response,
      });
    }
  });
}

function* getChildTaskRequest() {
  yield takeEvery(actions.GET_CHILD_TASKS_REQUEST, function* ({ payload }) {
    const { taskId } = payload;
    try {
      const childTasks = yield call(taskService.getTaskChildren, {
        taskId,
      });
      yield put({
        type: actions.GET_CHILD_TASKS_SUCCESS,
        childTasks: childTasks ? childTasks.tasks : [],
        taskId,
      });
    } catch (e) {
      yield put({
        type: actions.GET_CHILD_TASKS_ERROR,
        error: e.response,
      });
    }
  });
}

function* getTaskLog() {
  yield takeEvery(actions.GET_TASK_LOG_REQUEST, function* ({ payload }) {
    const { params } = payload;
    try {
      const response = yield call(taskService.getTaskLog, {
        params,
      });
      yield put({
        type: actions.GET_TASK_LOG_SUCCESS,
        taskLogs: response ? response : [],
      });
    } catch (e) {
      yield put({
        type: actions.GET_TASK_LOG_ERROR,
      });
    }
  });
}

function* getTasklogTasks() {
  yield takeEvery(actions.GET_TASKLOG_TASKS_REQUEST, function* ({ payload }) {
    const { params } = payload;
    try {
      const tasks = yield call(taskService.getTaskLogTasks, {
        params,
      });
      yield put({
        type: actions.GET_TASKLOG_TASKS_SUCCESS,
        tasklogTasks: tasks ? tasks.tasks : [],
      });
    } catch (e) {
      yield put({ type: actions.GET_TASKLOG_TASKS_ERROR });
    }
  });
}

function* getListTaskGanttChart() {
  yield takeEvery(actions.GET_LIST_TASK_GANTT_CHART_REQUEST, function* ({ payload }) {
    const {
      searchKeyword,
      storeId,
      status,
      priority,
      directorId,
      registerPersonId,
      assigneeId,
      size,
      page,
    } = payload;
    try {
      const tasks = yield call(taskService.getListTaskGanttChart, {
        searchKeyword,
        storeId,
        status,
        priority,
        directorId,
        assigneeId,
        registerPersonId,
        size,
        page,
      });
      const listTask = tasks
          ? tasks.tasks.map((child) => {
            if (child.storeId !== null && child.parentTaskId === null) {
              return child;
            }
            let obj = Object.assign({}, child);
            obj.children = [];
            return obj;
          })
          : [];
      yield put({
        type: actions.GET_LIST_TASK_GANTT_CHART_SUCCESS,
        taskGanttChart: listTask,
        tasks: listTask,
        total: tasks ? tasks.total : 0,
      });
    } catch (e) {
      yield put({
        type: actions.GET_LIST_TASK_GANTT_CHART_ERROR,
        error: e.message,
      });
    }
  });
}

function* getTaskAssignDataRequest() {
  yield takeEvery(actions.GET_TASK_ASSIGN_REQUEST, function* ({ payload }) {
    const {
      searchKeyword,
      status,
      size,
      page,
      doingUser,
      doneUser,
      assignedMember,
      endDateStart,
      endDateEnd,
      searchTeam,
      resolve,
      reject,
    } = payload;
    try {
      const tasks = yield call(taskService.getTaskAssign, {
        searchKeyword,
        status,
        doingUser,
        doneUser,
        assignedMember,
        endDateStart,
        endDateEnd,
        searchTeam,
        size,
        page,
        resolve,
        reject,
      });
      yield put({
        type: actions.GET_TASK_ASSIGN_SUCCESS,
        tasks: tasks,
        total: tasks ? tasks.total : 0,
      });
      yield resolve(tasks);
    } catch (e) {
      yield put({
        type: actions.GET_TASK_ASSIGN_ERROR,
        error: e.message,
      });
    }
  });
}


function* getTaskAchievement() {
  yield takeEvery(actions.GET_TASK_ACHIEVEMENT_REQUEST, function* ({ payload }) {
    const {
      team,
      member,
      fromStartDate,
      toStartDate,
      size,
      page,
      resolve,
      reject,
    } = payload;
    try {
      const tasks = yield call(taskService.getTaskAchievement, {
        team,
        member,
        fromStartDate,
        toStartDate,
        size,
        page,
        resolve,
        reject,
      });
      yield put({
        type: actions.GET_TASK_ACHIEVEMENT_SUCCESS,
        taskAchievement: tasks?.taskContentResult ?? [],
        total: tasks ? tasks.total : 0,
      });
      yield resolve(tasks);
    } catch (e) {
      yield put({
        type: actions.GET_TASK_ACHIEVEMENT_ERROR,
        error: e.message,
      });
    }
  });
}

export function* getAllOtas() {
  yield takeLatest(actions.GET_OTA_REQUEST, function* ({ payload }) {
    try {
      const [otaList] = yield all([
        call(contentAchievementService.getAllOtas),
      ]);
      yield put({
        type: actions.GET_OTA_SUCCESS,
        otas: otaList?.rows || [],
      });
    } catch (e) {
      yield put({ type: actions.GET_OTA_ERROR });
    }
  });
}

function* getContentAchievement() {
  yield takeEvery(actions.GET_CONTENT_ACHIEVEMENT_REQUEST, function* ({ payload }) {
    const {
      team,
      member,
      fromStartDate,
      toStartDate,
      otaId,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(contentAchievementService.getContentAchievement, {
        team,
        member,
        fromStartDate,
        toStartDate,
        otaId,
        resolve,
        reject,
      });
      yield put({
        type: actions.GET_CONTENT_ACHIEVEMENT_SUCCESS,
        contents: response ? response.contents : [],
        total: response ? response.total : 0,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.GET_CONTENT_ACHIEVEMENT_ERROR,
        error: e.message,
      });
    }
  });
}

function* getTaskDeliveryAchievement() {
  yield takeEvery(actions.GET_TASK_DELIVERY_ACHIEVEMENT_REQUEST, function* ({ payload }) {
    const {
      executedTeamArray,
      statusSearch,
      registerDateStart,
      registerDateEnd,
      startDateStart,
      startDateEnd,
      endDateStart,
      endDateEnd,
      actualTime,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(contentAchievementService.getTaskDeliveryAchievement, {
        executedTeamArray,
        statusSearch,
        registerDateStart,
        registerDateEnd,
        startDateStart,
        startDateEnd,
        endDateStart,
        endDateEnd,
        actualTime,
        resolve,
        reject,
      });
      yield put({
        type: actions.GET_TASK_DELIVERY_ACHIEVEMENT_SUCCESS,
        tasks: response ? response.tasks : [],
        total: response ? response.total : 0,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.GET_TASK_DELIVERY_ACHIEVEMENT_ERROR,
        error: e.message,
      });
    }
  });
}

function* getTaskAnalysis() {
  yield takeEvery(actions.GET_TASK_ANALYSIS_ACHIEVEMENT_REQUEST, function* ({ payload }) {
    const {
      searchKeyword,
      storeId,
      status,
      priority,
      directorId,
      registerPersonId,
      assigneeId,
      size,
      page,
      endDateStart,
      endDateEnd,
      registerDateStart,
      registerDateEnd,
      startDateStart,
      startDateEnd,
      searchTeam,
      personInCharge,
      resolve,
      reject,
    } = payload;
    try {
      const tasks = yield call(taskService.getTaskAnalysis, {
        searchKeyword,
        storeId,
        status,
        priority,
        directorId,
        assigneeId,
        registerPersonId,
        endDateStart,
        endDateEnd,
        registerDateStart,
        registerDateEnd,
        startDateStart,
        startDateEnd,
        searchTeam,
        personInCharge,
        size,
        page,
        resolve,
        reject,
      });
      yield put({
        type: actions.GET_TASK_ANALYSIS_ACHIEVEMENT_SUCCESS,
      });
    } catch (e) {
      yield put({
        type: actions.GET_TASK_ANALYSIS_ACHIEVEMENT_ERROR,
        error: e.message,
      });
    }
  });
}
export function* addSearchCondition() {
  yield takeLatest(actions.ADD_SEARCH_CONDITION_REQUEST, function* ({ payload }) {
    const { data, resolve, reject } = payload;
    try {
      const response = yield call(taskService.addSearchCondition, { ...data });
      yield put({
        type: actions.ADD_SEARCH_CONDITION_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.ADD_SEARCH_CONDITION_ERROR });
      reject(e);
    }
  });
}

export function* getSearchCondition() {
  yield takeLatest(actions.GET_SEARCH_CONDITION_REQUEST, function* ({ payload }) {
    const { resolve, reject } = payload;
    try {
      const response = yield call(taskService.getSearchCondition);
      yield put({
        type: actions.GET_SEARCH_CONDITION_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.GET_SEARCH_CONDITION_ERROR });
      reject(e);
    }
  });
}

function* changeTaskFixedDeadline() {
  yield takeEvery(actions.CHANGE_TASK_FIXED_DEADLINE_REQUEST, function* ({ payload }) {
    const {
      taskIds,
      fixedDeadline,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(taskService.changeTaskFixedDeadline, {
        taskIds,
        fixedDeadline,
        resolve,
        reject,
      });
      yield put({
        type: actions.CHANGE_TASK_FIXED_DEADLINE_SUCCESS,
        task: response
      });
      yield resolve(response);
    } catch (e) {
      yield reject(e.message);
      yield put({
        type: actions.CHANGE_TASK_FIXED_DEADLINE_ERROR,
        error: e.message,
      });
    }
  });
}

function* refreshListTask() {
  yield takeEvery(actions.REFRESH_LIST_TASK_REQUEST, function* ({ payload }) {
    const {
      tasks,
      taskId,
      fixedDeadline,
      isPickAll,
    } = payload;
    try {
      if (isPickAll) {
        tasks.map(task => {
          task.fixedDeadline = fixedDeadline;
          if (task.children !== null && task.children !== undefined && task.children.length > 0) {
            task.children.map(children => {
              children.fixedDeadline = fixedDeadline;
            });
          }
        })
      } else {
        tasks.map(task => {
          if (task.taskId === taskId) {
            task.fixedDeadline = fixedDeadline;
            if (task.children !== null && task.children !== undefined && task.children.length > 0) {
              task.children.map(children => {
                children.fixedDeadline = fixedDeadline;
              });
            }
          } else if (task.children !== null && task.children !== undefined && task.children.length > 0) {
            task.children.map(children => {
              if (children.taskId === taskId) {
                children.fixedDeadline = fixedDeadline;
              }
            });
          }
        });
      }

      yield put({
        type: actions.REFRESH_LIST_TASK_SUCCESS,
        tasks: tasks
      });
    } catch (e) {
      yield put({
        type: actions.REFRESH_LIST_TASK_ERROR,
        error: e.message,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getTaskDataRequest)]);
  yield all([fork(getTasksMetaData)]);
  yield all([fork(getChildTaskRequest)]);
  yield all([fork(getTaskLog)]);
  yield all([fork(getTasklogTasks)]);
  yield all([fork(getListTaskGanttChart)]);
  yield all([fork(getTaskAssignDataRequest)]);
  yield all([fork(getTaskAchievement)]);
  yield all([fork(getAllOtas)]);
  yield all([fork(getContentAchievement)]);
  yield all([fork(getTaskDeliveryAchievement)]);
  yield all([fork(getTaskAnalysis)]);
  yield all([fork(addSearchCondition)]);
  yield all([fork(getSearchCondition)]);
  yield all([fork(changeTaskFixedDeadline)]);
  yield all([fork(refreshListTask)]);
  yield all([fork(addDataTasks)]);
}
