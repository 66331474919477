import actions from "./actions";

const initialState = {
  task: {},
  loading: false,
  loadingMetaData: false,
  loadingUserNotifyList: false,
  loadingTaskLog: false,
  loadingSummaries: false,
  loadingTaskChildren: false,
  loadingTaskContent: false,
  amountGroupSelectModal: false,
  amountGroupPreviewModal: false,
  previewItem: {},
  taskLogs: [],
  summaries: [],
  userNotifyList: [],
  chainNotifyList: [],
  categoryList: [],
  userAssigneeList: [],
  taskChildren: [],
  taskLogsTotal: 0,
  summariesTotal: 0,
  taskChildrenTotal: 0,
  comments: [],
  attachComments: [],
  totalComment: 0,
  sumConfirmTime: 0,
  sumExecuteTime: 0,
  planDefaultList: [],
  taskMode: false,
  storeSelected: [],
  memberList: [],
  otaList: [],
  apiOtaList: [],
  contentList: [],
  taskContents: [],
  teamExecuteContents: [],
  teamConfirmContents: [],
};

export default function storeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_TASK_MODE: {
      return {
        ...state,
        task: {},
        storeSelected: [],
        taskMode: !state.taskMode,
      };
    }
    case actions.GET_TASK_DETAIL_REQUEST: {
      return {
        ...state,
        task: {},
        loading: true,
      };
    }
    case actions.GET_TASK_DETAIL_SUCCESS: {
      return {
        ...state,
        task: action.task,
        loading: false,
      };
    }
    case actions.GET_TASK_DETAIL_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.GET_TASK_DETAIL_COPY_REQUEST: {
      return {
        ...state,
        task: {},
        loading: true,
      };
    }
    case actions.GET_TASK_DETAIL_COPY_SUCCESS: {
      return {
        ...state,
        task: action.task,
        loading: false,
      };
    }
    case actions.GET_TASK_DETAIL_COPY_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.GET_TASK_METADATA_REQUEST: {
      return {
        ...state,
        loadingMetaData: true,
        categoryList: [],
        planDefaultList: [],
        userAssigneeList: [],
      };
    }
    case actions.GET_TASK_METADATA_SUCCESS: {
      return {
        ...state,
        categoryList: action.categoryList,
        planDefaultList: action.planDefaultList,
        userAssigneeList: action.userAssigneeList,
        loadingMetaData: false,
      };
    }
    case actions.GET_TASK_METADATA_ERROR: {
      return {
        ...state,
        loadingMetaData: false,
      };
    }
    case actions.GET_COMMENT_USER_NOTIFY_REQUEST: {
      return {
        ...state,
        loadingUserNotifyList: true,
        userNotifyList: [],
        chainNotifyList: [],
      };
    }
    case actions.GET_COMMENT_USER_NOTIFY_SUCCESS: {
      return {
        ...state,
        loadingUserNotifyList: false,
        userNotifyList: action.userNotifyList,
        chainNotifyList: action.chainNotifyList,
      };
    }
    case actions.GET_COMMENT_USER_NOTIFY_ERROR: {
      return {
        ...state,
        loadingUserNotifyList: false,
      };
    }
    case actions.ADD_TASK_REQUEST: {
      return {
        loading: true,
        ...state,
      };
    }
    case actions.ADD_TASK_SUCCESS: {
      return {
        ...state,
        loading: false,
        // task: action.task,
      };
    }
    case actions.ADD_TASK_ERROR: {
      return {
        loading: false,
        ...state,
      };
    }
    case actions.EDIT_TASK_SUCCESS: {
      return {
        ...state,
        task: action.task,
      };
    }
    case actions.GET_TASK_TASKLOG_REQUEST: {
      return {
        ...state,
        loadingTaskLog: true,
        taskLogs: [],
        taskLogsTotal: 0,
      };
    }
    case actions.GET_TASK_TASKLOG_SUCCESS: {
      return {
        ...state,
        loadingTaskLog: false,
        taskLogs: action.taskLogs,
        taskLogsTotal: action.taskLogsTotal,
      };
    }
    case actions.GET_TASK_TASKLOG_ERROR: {
      return {
        ...state,
        loadingTaskLog: false,
        taskLogs: [],
        taskLogsTotal: 0,
      };
    }
    case actions.GET_SUMMARIES_REQUEST: {
      return {
        ...state,
        loadingSummaries: true,
        summaries: [],
        summariesTotal: 0,
      };
    }
    case actions.GET_SUMMARIES_SUCCESS: {
      return {
        ...state,
        loadingSummaries: false,
        summaries: action.summaries,
        summariesTotal: action.summariesTotal,
      };
    }
    case actions.GET_SUMMARIES_ERROR: {
      return {
        ...state,
        loadingSummaries: false,
        summaries: [],
        summariesTotal: 0,
      };
    }
    case actions.GET_COMMENTS_SUCCESS: {
      return {
        ...state,
        comments: action.comments,
        totalComment: action.totalComment,
      };
    }
    case actions.CREATE_COMMENT_SUCCESS: {
      return {
        ...state,
        comments: action.comment.commentId !== null && action.comment.commentId !== undefined && action.comment.commentId > 0 ? [...state.comments, action.comment] : [...state.comments],
        totalComment: action.comment.commentId !== null && action.comment.commentId !== undefined && action.comment.commentId > 0 ? state.totalComment + 1 : state.totalComment,
        task: {
          ...state.task,
          ...action.task,
        },
      };
    }
    case actions.GET_TASKS_CHILDREN_REQUEST: {
      return {
        ...state,
        loadingTaskChildren: true,
        taskChildren: [],
        taskChildrenTotal: 0,
      };
    }
    case actions.GET_TASKS_CHILDREN_SUCCESS: {
      return {
        ...state,
        loadingTaskChildren: false,
        taskChildren: action.tasks,
        taskChildrenTotal: action.total,
      };
    }
    // case actions.GET_TASK_TOTAL_TIMES_REQUEST: {
    //   return {
    //     ...state,
    //     sumConfirmTime: 0,
    //     sumExecuteTime: 0,
    //   };
    // }
    case actions.GET_TASK_TOTAL_TIMES_SUCCESS: {
      return {
        ...state,
        sumConfirmTime: action.sumConfirmTime,
        sumExecuteTime: action.sumExecuteTime,
      };
    }
    case actions.EDIT_TASKLOG_REQUEST: {
      return {
        ...state,
      };
    }
    case actions.EDIT_TASKLOG_SUCCESS: {
      return {
        ...state,
      };
    }
    case actions.EDIT_TASKLOG_ERROR: {
      return {
        ...state,
      };
    }
    case actions.RESET_TASK_ADD_EDIT_STORE: {
      return initialState;
    }
    case actions.TOGGLE_AMOUNT_GROUP_SELECT: {
      return {
        ...state,
        amountGroupSelectModal: !state.amountGroupSelectModal,
      };
    }
    case actions.TOGGLE_AMOUNT_GROUP_PREVIEW: {
      return {
        ...state,
        amountGroupPreviewModal: !state.amountGroupPreviewModal,
      };
    }
    case actions.SET_ITEM_PREVIEW: {
      return {
        ...state,
        previewItem: action.payload,
      };
    }
    case actions.SET_TASK: {
      return {
        ...state,
        task: {},
      };
    }
    case actions.SET_STORE_SELECTED: {
      return {
        ...state,
        storeSelected: action.payload,
      };
    }
    case actions.RESET_TASK: {
      return {
        ...state,
        task: {},
      };
    }
    case actions.EDIT_COMMENT_REQUEST: {
      return {
        ...state,
      };
    }
    case actions.EDIT_COMMENT_SUCCESS: {
      return {
        ...state,
        comments: action.comments,
      };
    }
    case actions.EDIT_COMMENT_ERROR: {
      return {
        ...state,
      };
    }
    case actions.GET_LIST_USER: {
      return {
        ...state,
        userAssigneeList: [],
      };
    }
    case actions.GET_LIST_USER_SUCCESS: {
      return {
        ...state,
        userAssigneeList: action.userAssigneeList,
      };
    }
    case actions.GET_LIST_USER_ERROR: {
      return {
        ...state,
      };
    }
    case actions.GET_MEMBER_LIST_REQUEST: {
      return {
        ...state,
        memberList: [],
      };
    }
    case actions.GET_MEMBER_LIST_SUCCESS: {
      return {
        ...state,
        memberList: action.memberList,
      };
    }
    case actions.GET_MEMBER_LIST_ERROR: {
      return {
        ...state,
        memberList: [],
      };
    }
    case actions.GET_OTA_LIST_REQUEST: {
      return {
        ...state,
        otaList: [],
      };
    }
    case actions.GET_OTA_LIST_SUCCESS: {
      return {
        ...state,
        otaList: action.otaList,
      };
    }
    case actions.GET_OTA_LIST_ERROR: {
      return {
        ...state,
        otaList: [],
      };
    }
    case actions.GET_API_OTA_LIST_REQUEST: {
      return {
        ...state,
        apiOtaList: [],
      };
    }
    case actions.GET_API_OTA_LIST_SUCCESS: {
      return {
        ...state,
        apiOtaList: action.apiOtaList,
      };
    }
    case actions.GET_API_OTA_LIST_ERROR: {
      return {
        ...state,
        apiOtaList: [],
      };
    }
    case actions.GET_CONTENT_LIST_REQUEST: {
      return {
        ...state,
        contentList: [],
      };
    }
    case actions.GET_CONTENT_LIST_SUCCESS: {
      return {
        ...state,
        contentList: action.contentList,
      };
    }
    case actions.GET_CONTENT_LIST_ERROR: {
      return {
        ...state,
        contentList: [],
      };
    }
    case actions.GET_TASK_CONTENT_REQUEST: {
      return {
        ...state,
        loadingTaskContent: true,
        taskContents: [],
      };
    }
    case actions.GET_TASK_CONTENT_SUCCESS: {
      return {
        ...state,
        loadingTaskContent: false,
        taskContents: action.taskContents,
      };
    }
    case actions.GET_TASK_CONTENT_ERROR: {
      return {
        ...state,
        loadingTaskContent: false,
        taskContents: [],
      };
    }
    case actions.GET_TEAM_CONTENT_LIST_REQUEST: {
      return {
        ...state,
      };
    }
    case actions.GET_TEAM_CONTENT_LIST_SUCCESS: {
      return {
        ...state,
      };
    }
    case actions.GET_TEAM_CONTENT_LIST_ERROR: {
      return {
        ...state,
      };
    }
    case actions.GET_TEAM_EXECUTE_CONTENT_LIST_REQUEST: {
      return {
        ...state,
        teamExecuteContents: []
      };
    }
    case actions.GET_TEAM_EXECUTE_CONTENT_LIST_SUCCESS: {
      return {
        ...state,
        teamExecuteContents: action.teamExecuteContents,
      };
    }
    case actions.GET_TEAM_EXECUTE_CONTENT_LIST_ERROR: {
      return {
        ...state,
        teamExecuteContents: []
      };
    }
    case actions.GET_TEAM_CONFIRM_CONTENT_LIST_REQUEST: {
      return {
        ...state,
        teamConfirmContents: []
      };
    }
    case actions.GET_TEAM_CONFIRM_CONTENT_LIST_SUCCESS: {
      return {
        ...state,
        teamConfirmContents: action.teamConfirmContents,
      };
    }
    case actions.GET_TEAM_CONFIRM_CONTENT_LIST_ERROR: {
      return {
        ...state,
        teamConfirmContents: []
      };
    }
    case actions.GET_TASK_CONTENT_TOTAL_TIMES_REQUEST: {
      return {
        ...state
      };
    }
    case actions.GET_TASK_CONTENT_TOTAL_TIMES_SUCCESS: {
      return {
        ...state
      };
    }
    case actions.GET_TASK_CONTENT_TOTAL_TIMES_ERROR: {
      return {
        ...state
      };
    }
    case actions.TASK_GET_MANUAL_FILE_REQUEST:
      return {
        ...state,
      };
    case actions.TASK_GET_MANUAL_FILE_SUCCESS:
      return {
        ...state,
      };
    case actions.TASK_GET_MANUAL_FILE_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.EXECUTE_TASK_CONTENT_STOP_WATCH: {
      return {
        ...state,
      };
    }
    case actions.EXECUTE_TASK_CONTENT_STOP_WATCH_SUCCESS: {
      if (action.task.taskId > 0) {
        return {
          ...state,
          task: action.task,
          comments: action.comment !== null && action.comment !== undefined &&
                    action.comment.commentId !== null && action.comment.commentId !== undefined && action.comment.commentId > 0 ? [...state.comments, action.comment] : state.comments,
        };
      } else {
        return {
          ...state
        };
      }
    }
    case actions.EXECUTE_TASK_CONTENT_STOP_WATCH_ERROR: {
      return {
        ...state,
        task: {}
      };
    }
    case actions.TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY_REQUEST: {
      return {
        ...state,
      };
    }
    case actions.TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY_SUCCESS: {
        return {
          ...state,
        };
    }
    case actions.TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY_ERROR: {
      return {
        ...state,
        task: {}
      };
    }
    case actions.EDIT_TASK_ASSIGN_SUCCESS: {
      return {
        ...state,
        task: action.task,
      };
    }
    case actions.UPDATE_TASK_MEMO_REQUEST: {
      return {
        ...state,
      };
    }
    case actions.UPDATE_TASK_MEMO_SUCCESS: {
      return {
        ...state,
      };
    }
    case actions.UPDATE_TASK_MEMO_ERROR: {
      return {
        ...state,
      };
    }
    case actions.TASK_ATTACH_UPLOAD_FILE_COMMENT_REQUEST:
      return {
        ...state,
      };
    case actions.TASK_ATTACH_UPLOAD_FILE_COMMENT_SUCCESS:
      return {
        ...state,
      };
    case actions.TASK_ATTACH_UPLOAD_FILE_COMMENT_ERROR:
      return {
        ...state,
    };
    case actions.GET_TASK_ATTACH_FILE_COMMENT_REQUEST:
      return {
        ...state,
        attachComments: []
      };
    case actions.GET_TASK_ATTACH_FILE_COMMENT_SUCCESS:
      return {
        ...state,
        attachComments: action.attachComments
      };
    case actions.GET_TASK_ATTACH_FILE_COMMENT_ERROR:
      return {
        ...state,
        attachComments: []
      };
    case actions.DELETE_TASK_ATTACH_FILE_COMMENT_REQUEST:
      return {
        ...state,
      };
    case actions.DELETE_TASK_ATTACH_FILE_COMMENT_SUCCESS:
      return {
        ...state,
      };
    case actions.DELETE_TASK_ATTACH_FILE_COMMENT_ERROR:
      return {
        ...state,
    };
    default:
      return state;
  }
}
