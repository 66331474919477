import AuthService from "./auth.service";
import AccountService from "./account.service";
import PasswordHistoryService from "./passwordHistory.service";
import StoreService from "./store.service";
import ChainService from "./chain.service";
import TaskService from "./task.service";
import SelectStoreService from "./selectStore.service";
import HelpService from "./help.service";
import DownloadService from "./download.service";
import NotifyService from "./notify.service";
import DashboardService from "./dashboard.service";
import UploadService from "./upload.service";
import GanttChartService from "./ganttChart.service";
import OTAService from "./ota.service";
import PlanAmountService from "./planAmount.service";
import ContentService from "@iso/services/content.service";
import ContentAchievementService from "@iso/services/contentAchievementService.service";
import CrawlPlanSaleService from "@iso/services/crawlPlanSale.service";
import TaskCopyHistoryService from "@iso/services/taskCopyHistory.service"
import TaskStoreConfigService from "@iso/services/taskStoreConfig.service"
import CrawlCouponService from "@iso/services/crawlCoupon.service"
import CrawlSaleStatisticService from "@iso/services/crawlSaleStatistic.service";
import RequestTimeService from "@iso/services/requestTime.service"
import TotalTimeWorkService from "@iso/services/totalTimeWork.service"
import CrawlPrinceAchievementService from "@iso/services/crawlPrinceAchievement.service"
import AggregateService from "./aggregate.service";
import TemplateService from "@iso/services/template.service";
import AutoCopyTaskService from "@iso/services/autoCopyTask.service";
import ApiPlanOtaService from "@iso/services/apiPlanOta.service";
import SelectApiRoomService from "./selectApiRoom.service";
import ApiRoomService from "@iso/services/apiRoom.service"
import ApiImageCategoryService from "@iso/services/apiImageCategory.service";
import ApiImageService from "@iso/services/apiImageservice";
import ApiOptionValuesService from "@iso/services/apiOptionValues.service";
import ApiCrawlRoomService from "@iso/services/apiCrawlRoom.service";
import ApiCrawlPlanService from "@iso/services/apiCrawlPlan.service";
import ApiCancelPolicyService from "@iso/services/apiCancelPolicy.service";
import CrawlVisitorToHennaHotelService from "@iso/services/crawlVisitorToHennaHotel.service";

export const authService = new AuthService({});
export const accountService = new AccountService({});
export const chainService = new ChainService({});
export const passwordHistoryService = new PasswordHistoryService({});
export const storeService = new StoreService({});
export const taskService = new TaskService({});
export const selectStoreService = new SelectStoreService({});
export const helpService = new HelpService({});
export const downloadService = new DownloadService({});
export const notifyService = new NotifyService({});
export const dashboardService = new DashboardService({});
export const uploadService = new UploadService({});
export const ganttChartService = new GanttChartService({});
export const otaService = new OTAService({});
export const planAmountService = new PlanAmountService({});
export const contentService = new ContentService({});
export const contentAchievementService = new ContentAchievementService({});
export const taskCopyHistoryService = new TaskCopyHistoryService({});
export const taskStoreConfigService = new TaskStoreConfigService({});
export const aggregateService = new AggregateService({});
export const crawlPlanSaleService = new CrawlPlanSaleService({});
export const crawlCouponService = new CrawlCouponService({});
export const crawlSaleStatisticService = new CrawlSaleStatisticService({});
export const requestTimeService = new RequestTimeService({});
export const totalTimeWorkService = new TotalTimeWorkService({});
export const crawlPrinceAchievementService = new CrawlPrinceAchievementService({});
export const templateService = new TemplateService({});
export const autoCopyTaskService = new AutoCopyTaskService({});

export const apiPlanOtaService = new ApiPlanOtaService({});
export const selectApiRoomService = new SelectApiRoomService({});
export const apiRoomService = new ApiRoomService({});
export const apiImageCategoryService = new ApiImageCategoryService({});
export const apiImageService = new ApiImageService({});
export const apiOptionValuesService = new ApiOptionValuesService({});
export const apiCrawlRoomService = new ApiCrawlRoomService({});
export const apiCrawlPlanService = new ApiCrawlPlanService({});
export const apiCancelPolicyService = new ApiCancelPolicyService({});
export const crawlVisitorToHennaHotelService = new CrawlVisitorToHennaHotelService({});
