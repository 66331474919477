import BaseService from "./base.service";
import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import Axios from "axios";
import {ACCESS_TOKEN_KEY} from "@iso/constants/common.constant";
import FileSaver from 'file-saver'

export default class TaskService extends BaseService {
  getTasks = ({ ...data }) => {
    return this.axios.post(API_ENDPOINTS.TASKS_SEARCH, {
      ...data,
    });
  };

  getTaskLog = ({ params }) => {
    return this.axios.get(`${API_ENDPOINTS.ADMIN_TASKLOGS}${params}`);
  };

  getTaskLogTasks = ({ params }) => {
    return this.axios.get(`${API_ENDPOINTS.TASKLOGS_TASKS}${params}`);
  };

  getAssignees = (values) => {
    const { list_role } = values;
    return this.axios.get(`${API_ENDPOINTS.LIST_USERS}`, {
      params: {
        roles: list_role,
      },
    });
  };

  getRegisterPersons = () => {
    return this.axios.get(API_ENDPOINTS.LIST_REGISTERS);
  };

  getDirectors = ({ currentDirector }) => {
    if (currentDirector === undefined || currentDirector === null) {
      currentDirector = "";
    }
    return this.axios.get(
        API_ENDPOINTS.LIST_DIRECTORS.replace("{currentDirector}", currentDirector)
    );
  };

  getStores = ({ chainId }) => {
    return this.axios.post(API_ENDPOINTS.STORES, { chainId });
  };

  getCategoryList = () => {
    return this.axios.get(API_ENDPOINTS.CATEGORIES);
  };

  getPlanDefaultList = () => {
    return this.axios.get(API_ENDPOINTS.PLANS);
  };

  getUserAssigneeList = (values) => {
    const { list_role, sort_by, currentAccount } = values;
    return this.axios.get(`${API_ENDPOINTS.USERS}`, {
      params: {
        roles: list_role,
        sortBy: sort_by,
        currentAccount: currentAccount,
      },
    });
  };

  getCommentUserNotifyRequest = ({ id, type }) => {
    return this.axios.get(API_ENDPOINTS.USERS_MENTION_TASK.replace("{taskId}", id), {
      params: {
        type,
      },
    });
  };

  getTaskDetail = ({ taskId }) => {
    return this.axios.get(`${API_ENDPOINTS.TASKS}/${taskId}`);
  };

  createTask = (data) => {
    return this.axios.post(API_ENDPOINTS.TASK, data);
  };

  editTask = ({ taskId, data }) => {
    return this.axios.put(`${API_ENDPOINTS.TASKS}/${taskId}`, data);
  };

  fetchTaskLogs = ({ taskId, ...data }) => {
    return this.axios.post(API_ENDPOINTS.TASK_TASKLOGS.replace("{taskId}", taskId), {
      ...data,
      sortBy: "task_log_date",
      sortByType: "-1",
    });
  };

  fetchSummaries = ({ taskId, ...data }) => {
    return this.axios.post(
      API_ENDPOINTS.TASK_TASKLOG_SUMMARIES.replace("{taskId}", taskId),
      {
        ...data,
      }
    );
  };

  getComments = ({ taskId, data }) => {
    return this.axios.post(
      API_ENDPOINTS.TASK_COMMENTS.replace("{taskId}", taskId),
      data
    );
  };

  createComment = (data) => {
    return this.axios.post(API_ENDPOINTS.ADD_COMMENTS, data);
  };

  getTaskChildren = ({ taskId, ...data }) => {
    return this.axios.get(API_ENDPOINTS.TASK_CHILDREN.replace("{taskId}", taskId), {
      data,
    });
  };

  fetchTaskLogsLatest = ({ taskId }) => {
    return this.axios.get(
      API_ENDPOINTS.TASK_TASKLOGS_LATEST.replace("{taskId}", taskId)
    );
  };

  executeStopWatch = ({ taskId, action, type }) => {
    return this.axios.post(
      API_ENDPOINTS.TASK_STOPWATCH.replace("{taskId}", taskId),
      {
        action,
        type,
      }
    );
  };

  getTaskTotalTimes = ({ taskId }) => {
    return this.axios.get(
      API_ENDPOINTS.TASK_TOTAL_TIMES.replace("{taskId}", taskId)
    );
  };

  editTaskLog = ({ taskId, taskLogId, datetime }) => {
    return this.axios.put(
      API_ENDPOINTS.TASK_EDIT_TASKLOG.replace("{taskId}", taskId).replace(
        "{taskLogId}",
        taskLogId
      ),
      {
        datetime,
      }
    );
  };

  getListTaskGanttChart = ({ ...data }) => {
    return this.axios.post(API_ENDPOINTS.TASKS_GANTT_CHART, {
      ...data,
    });
  };

  editComment = ({ commentId, commentText, taskId, type, size, sortBy, sortByType, }) => {
    return this.axios.put(
        API_ENDPOINTS.EDIT_COMMENT.replace("{commentId}", commentId),
        {
          commentText,
          taskId,
          type,
          size,
          sortBy,
          sortByType,
        }
    );
  };

  fetchTaskContents = ({ taskId, createdAt }) => {
    return this.axios.post(API_ENDPOINTS.TASK_CONTENTS.replace("{taskId}", taskId), {createdAt});
  };

  fetchTaskContentLogsLatest = ({ taskId }) => {
    return this.axios.get(
        API_ENDPOINTS.TASK_CONTENT_TASKLOGS_LATEST.replace("{taskId}", taskId)
    );
  };

  getTaskContentTotalTimes = ({ taskId }) => {
    return this.axios.get(
        API_ENDPOINTS.TASK_CONTENT_TOTAL_TIMES.replace("{taskId}", taskId)
    );
  };

  executeTaskContentStopWatch = ({ taskId, taskContentId, action, type, accountId }) => {
    return this.axios.post(
        API_ENDPOINTS.TASK_CONTENT_STOPWATCH.replace("{taskId}", taskId),
        {
          action,
          type,
          taskContentId,
          accountId,
        }
    );
  };

  getTaskAssign = ({ ...data }) => {
    return this.axios.post(API_ENDPOINTS.TASK_ASSIGN, {
      ...data,
    });
  };

  editTaskAssign = ({ taskId, data }) => {
    return this.axios.put(`${API_ENDPOINTS.TASKS}/task-assign/${taskId}`, data);
  };

  getTaskAchievement = ({ ...data }) => {
    return this.axios.post(API_ENDPOINTS.TASK_ACHIEVEMENT, {
      ...data,
    });
  };

  taskAttachUploadBySubCategory = ({taskId, subCategoryId}) => {
    return this.axios.post(API_ENDPOINTS.TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY.replace("{taskId}", taskId), {
      subCategoryId
    });
  }

  getTaskAnalysis = ({ ...data }) => {
    Axios({
      url: `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_API_PRE_ENDPOINT}/task/task-analysis`,
      method: 'POST',
      responseType: 'blob',
      data: {...data},
      headers: {
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_KEY)
      }
    }).then((response) => {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, '????????.xlsx');
    })
  };

  addSearchCondition = (data) => {
    return this.axios.post(API_ENDPOINTS.ADD_SEARCH_CONDITION, data);
  };

  getSearchCondition = () => {
    return this.axios.get(API_ENDPOINTS.GET_SEARCH_CONDITION);
  };

  changeTaskFixedDeadline = ({ ...data }) => {
    return this.axios.post(API_ENDPOINTS.CHANGE_TASK_FIXED_DEADLINE, {
      ...data
    });
  };

  updateTaskMemo = ({ taskId, data }) => {
    return this.axios.post(`${API_ENDPOINTS.TASKS}/update-memo/${taskId}`, data);
  };
  
  attachFileComment = (data) => {
    const formData = new FormData();
    formData.append("attachFile", data.file);
    formData.append("taskId", data.taskId);
    return this.axios.post(`${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_API_PRE_ENDPOINT}${API_ENDPOINTS.TASK_ATTACH_FILE_COMMENT}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_KEY),
        },
    });
  };

  getAttachFileComments = (taskId) => {
    return this.axios.post(API_ENDPOINTS.GET_TASK_ATTACH_FILE_COMMENT.replace("{taskId}", taskId));
  };
  
  deleteAttachFileComment = (taskAttachId) => {
    return this.axios.delete(API_ENDPOINTS.DELETE_TASK_ATTACH_FILE_COMMENT.replace("{taskAttachId}", taskAttachId));
  };
}
